import theme from './theme'
import phonemask from './phonemask/phonemask'
import scrollTo from './scrollTo'
import toggle from 'npm-kit-toggle'
import ripple from '@qpokychuk/ripple'
import animation from './animation'
// import swiper from './swiper'
// import fancybox from './fancybox'
// import ymaps from './ymaps'

import '../scss/index.scss'

window.addEventListener('DOMContentLoaded', () => loadHandler())

function loadHandler() {
  scrollTo.init()
  toggle.init()
  ripple.init()
  theme.init()
  // fancybox.init()
  phonemask.init('[type="tel"]')
  // ymaps.init()

  ripple.attach('.btn')
  ripple.attach('.waved')
  ripple.deAttach('.btn-text')

  // swiper.init()

  window.addEventListener('scroll', () => {
    document.body.classList.toggle('scroll-top', window.scrollY == 0)
  })
  document.body.classList.toggle('scroll-top', window.scrollY == 0)
}

window.addEventListener('load', () => {
  document.body.classList.add('loaded')
  animation.init()
})
