import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const gsapAnimation: Record<string, any> = {
  fadeInLeft: {
    from: { x: '-75', opacity: 0 },
    to: { x: 0, opacity: 1 },
  },
  fadeInRight: {
    from: { x: '75', opacity: 0 },
    to: { x: 0, opacity: 1 },
  },
  fadeInUp: {
    from: { y: '75', opacity: 0 },
    to: { y: 0, opacity: 1 },
  },
  fadeInDown: {
    from: { y: '-75', opacity: 0 },
    to: { y: 0, opacity: 1 },
  },
  fadeIn: {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  scaleIn: {
    from: { scale: 1.5 },
    to: { scale: 1 },
  },
}

function init() {
  gsap.registerPlugin(ScrollTrigger)
  ScrollTrigger.config({
    ignoreMobileResize: true,
  })

  magneticInit()
  startedInit()
  scrollInit()

  window.addEventListener('toggleopen', toggleHandler)
  window.addEventListener('toggleclose', toggleHandler)

  window.addEventListener('resize', () => {
    setTimeout(() => {
      ScrollTrigger.refresh()
    }, 500)
  })
}

function toggleHandler(event: any) {
  if (event.detail.target.closest('.questions')) {
    ScrollTrigger.refresh()
  }
}

function startedInit() {
  const startedImages = document.querySelectorAll('.started-image')

  gsap.to(startedImages, {
    keyframes: [
      {
        rotateY: 180,
        ease: 'power4.inOut',
      },
      {
        rotateY: 360,
        ease: 'power4.inOut',
      },
    ],
    duration: 8,
    stagger: 0.06,
    repeat: -1,
    scrollTrigger: {
      trigger: '.started-image-wrapper',
      toggleActions: 'play pause resume reset',
    },
  })
}

function scrollInit() {
  const scrollElements = document.querySelectorAll('[data-animation]')

  scrollElements.forEach((element) => {
    const key = element.getAttribute('data-animation')
    const duration = element.getAttribute('data-duration') || 1
    const end = element.getAttribute('data-end') || 'bottom 10%'
    const start = element.getAttribute('data-start') || 'top 90%'
    const delay = element.getAttribute('data-delay') || 0.1
    const actions = element.getAttribute('data-actions') || 'play reverse play reverse'

    const animation = gsapAnimation[key || '']

    if (!animation) {
      return
    }

    const instance = gsap.fromTo(element, animation.from, {
      ...animation.to,
      duration,
      delay,
      ease: 'elastic.out(1,1)',
      scrollTrigger: {
        trigger: element,
        start: start,
        end: end,
        toggleActions: actions,

        // scrub: true,
        // markers: true,
        // id: "scrub"
      },
    })

    instance.then(() => {
      gsap.set(element, {
        transform: 'none',
      })
    })
  })
}

function magneticInit() {
  const force = 50
  const items = document.querySelectorAll('.magnetic-wrap')

  items.forEach((item) => {
    const target = item.querySelector('.magnetic-target')

    item.addEventListener('mousemove', (e) => {
      const event = e as MouseEvent
      const boundingRect = item.getBoundingClientRect()

      const relX = event.clientX - boundingRect.left
      const relY = event.clientY - boundingRect.top

      gsap.to(target, {
        x: ((relX - boundingRect.width / 2) / boundingRect.width) * force,
        y: ((relY - boundingRect.height / 2) / boundingRect.height) * force,
        duration: 0.5,
      })
    })

    item.addEventListener('mouseout', () => {
      gsap.to(target, {
        x: 0,
        y: 0,
        duration: 0.5,
        ease: 'elastic',
      })
    })
  })
}

export default { init }
